<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading_clientes"
      color="greyTxt"
      opacity=".4"
    />
    <back class="mt-6" />
    <div
      class="ml-2 font-weight-medium text-h5 greyTxt--text pt-4 mb-4 "
    >
      Clientes
    </div>

    <v-row>
      <v-col
        cols="24"
        md="12"
      >
        <v-card
          elevation="2"
        >
          <!-- Tabla de Pedidos -->
          <generic-table
            :loading="loading_clientes"
            :headers="headers_clientes"
            :items="clientes"
            height="unset"
            :options="{
              'itemsPerPage':10
            }"

            :actions="actions_clientes"
            @openPedidos="openPedidos"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { get, call } from 'vuex-pathify'
  export default {
    name: 'DashboardView',

    data () {
      return {

        actions_clientes: [
          {
            icon: 'eye',
            title: 'Pedidos',
            color: 'primary',
            emit: 'openPedidos',
          },
        ],

      }
    },

    computed: {
      clientes: get('clientes/items'),
      headers_clientes: get('clientes/headers'),
      loading_clientes: get('clientes/loading'),
      count_remitos: get('clientes/count'),

    },
    created () {
      this.initialize()
    },
    methods: {
      getClientes: call('clientes/getData'),
      initialize () {
        this.getClientes()
      },

      openPedidos (details) {
        this.$router.push(`/pedidos/${details.id_cliente}/${details.raz_soc}`)
      },
    },
  }
</script>
